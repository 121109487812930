const processInnerQuestion = (innerQuestion, item, dataToReturn) => {
  const valueToCompare = innerQuestion.valueToCompare?.toLowerCase()?.trim();
  const itemValue = item.value?.toLowerCase()?.trim();

  if (
    valueToCompare == itemValue &&
    innerQuestion.condition == "equalto" &&
    !innerQuestion.value &&
    innerQuestion.required
  ) {
    dataToReturn.isInvalid = true;
    dataToReturn.idToScroll = dataToReturn.idToScroll || innerQuestion.id;
    return { ...innerQuestion, invalid: true };
  }

  if (
    valueToCompare != itemValue &&
    innerQuestion.condition == "notequalto" &&
    !innerQuestion.value &&
    innerQuestion.required
  ) {
    dataToReturn.isInvalid = true;
    dataToReturn.idToScroll = dataToReturn.idToScroll || innerQuestion.id;

    return { ...innerQuestion, invalid: true };
  }

  if (
    innerQuestion?.showAlways &&
    !innerQuestion.value &&
    innerQuestion.required
  ) {
    dataToReturn.isInvalid = true;
    dataToReturn.idToScroll = dataToReturn.idToScroll || innerQuestion.id;

    return { ...innerQuestion, invalid: true };
  }

  return { ...innerQuestion, invalid: false };
};

const processOuterQuestion = (item, dataToReturn) => {
  const innerQuestions = item.innerQuestions.map((innerQuestion) =>
    processInnerQuestion(innerQuestion, item, dataToReturn)
  );

  if (item.required && !item.value) {
    dataToReturn.isInvalid = true;

    dataToReturn.idToScroll = dataToReturn.idToScroll || item.id;
    return { ...item, invalid: true, innerQuestions };
  }

  return { ...item, invalid: false, innerQuestions };
};

export const validateForm = (questions) => {
  const dataToReturn = {
    isInvalid: false,
    idToScroll: "",
    updated: {},
  };

  Object.keys(questions).forEach((key) => {
    dataToReturn.updated[key] = questions[key].map((item) =>
      processOuterQuestion(item, dataToReturn)
    );
  });

  if (dataToReturn.idToScroll) {
    scrollIntoView(dataToReturn.idToScroll);
  }
  return dataToReturn;
};

export const validateSingleObject = (keyToUpdaye, questions) => {
  const dataToReturn = {
    isInvalid: false,
    idToScroll: "",
    updated: {},
  };
  Object.keys(questions).forEach((key) => {
    if (key == keyToUpdaye) {
      dataToReturn.updated[key] = questions[key].map((item) =>
        processOuterQuestion(item, dataToReturn)
      );
    } else {
      dataToReturn.updated[key] = questions[key];
    }
  });

  if (dataToReturn.idToScroll) {
    scrollIntoView(dataToReturn.idToScroll);
  }
  return dataToReturn;
};

const scrollIntoView = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }
};
