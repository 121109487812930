export const errorToast = (message) =>
  window.swal.fire({
    icon: "error",
    title: "Error",
    text: message,
  });

export const errorToastReloadAsync = async (message) => {
  window.swal
    .fire({
      icon: "error",
      title: "Error",
      text: message,
    })
    .then((val) => window.location.reload());
};

export const errorToastHtml = (message) => {
  window.swal.fire({
    icon: "error",
    title: "Error",
    html: message,
  });
};

export const successToast = (message, title, options) => {
  window.swal.fire({
    icon: "success",
    title: title,
    text: message,
    ...options,
  });
};

export const infoToast = async (message, title, callback) => {
  window.swal
    .fire({
      icon: "info",
      title: title,
      text: message,
      showCancelButton: true,
    })
    .then((val) => callback(val));
};

export const processForVerifyToast = () => {
  window.swal.fire({
    icon: "info",
    title: "Processing",
    text: "Please wait for your ID to be processed. This may take 60-90 seconds. Next you will do a biometric analysis.",
    showConfirmButton: false,
    showCancelButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
  });
};
