import axios from "axios";
import { AUTH_URL, BASE_URL } from "../constant";
import { errorToast } from "./toast";

const baseUrl = BASE_URL;

export const callApi = async (
  method,
  path,
  dataToPost = {},
  checkForSuccess = true,
  queryParams = ""
) => {
  let config = {
    withCredentials: true,
    headers: {
      Authorization: localStorage.getItem("jwt_ahrvo"),
    },
  };

  try {
    let url = baseUrl + path + "?jwt=" + localStorage.getItem("jwt_ahrvo");
    if (queryParams) {
      url = url + "&" + queryParams;
    }

    if (method == "get") {
      const { data } = await axios.get(url, config);
      return handleResponse(data, checkForSuccess);
    }

    const { data } = await axios.post(url, dataToPost);
    return handleResponse(data, checkForSuccess);
  } catch (error) {
    return handleError(error);
  }
};
const handleResponse = (data, checkForSuccess = true) => {
  if (!checkForSuccess) {
    return data;
  }
  if (data.success) {
    return data;
  } else {
    errorToast(
      data.message || data.msg || "An error occurred, please try again"
    );
  }
  return null;
};
const handleError = (error) => {
  window.swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Something went wrong!",
  });
  return null;
};

export const callAuthAPi = async (
  method,
  path,
  dataToPost = {},
  checkForSuccess = true
) => {
  let config = {
    withCredentials: true,
  };

  try {
    if (method == "get") {
      let url = AUTH_URL + path + "?jwt=" + localStorage.getItem("jwt_ahrvo");

      const { data } = await axios.get(url, config);
      return handleResponse(data, checkForSuccess);
    }

    const url = AUTH_URL + path + "?jwt=" + localStorage.getItem("jwt_ahrvo");

    const { data } = await axios.post(url, dataToPost);
    return handleResponse(data, checkForSuccess);
  } catch (error) {
    return handleError(error);
  }
};
