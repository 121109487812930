import axios from "axios";
import React, { useEffect, useRef, useState, lazy, Suspense } from "react";
import {
  BASE_URL,
  BIOMETRIC_BACKEND_URL,
  CDN_URL,
  DEEPFACE_BACKEND_URL,
  MAIN_APP_URL,
} from "./constant";
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
import { PrivateRoute, PubliceRoute } from "./routing/PrivateRoute";
import "./App.css";
import { FullScreenLoader } from "./common/Spinner";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import WolfbergQuestionaire from "./wolfbergs-questionaire";
import { SafariPage } from "./Safari";
const Welcome = lazy(() => import("./Welcome/welcome"));
const Signup = lazy(() => import("./auth/Signup"));

const Login = lazy(() => import("./auth/Login"));

const OtpVerification = lazy(() => import("./auth/OtpVerification"));

const VerificationFormMainApp = lazy(() =>
  import("./verification/VerificationHOC.MainApp")
);
const Biometric = lazy(() => import("./Biometrics/Biometrics.MainApp"));
const Thankyou = lazy(() => import("./thankyou/Thankyou.mainapp"));

const UserDetails = lazy(() => import("./Welcome/UserDetails"));

const router = createBrowserRouter([
  {
    path: "/user-login",
    element: (
      <PubliceRoute>
        <Login />
      </PubliceRoute>
    ),
  },
  {
    path: "/user-questionaire",
    element: <WolfbergQuestionaire />,
  },
  {
    path: "/",
    element: (
      <PubliceRoute>
        <Login />
      </PubliceRoute>
    ),
  },
  {
    path: "/user-signup",
    element: (
      <PubliceRoute>
        <Signup />
      </PubliceRoute>
    ),
  },
  {
    path: "/otp-verification",
    element: (
      <PubliceRoute>
        <OtpVerification />
      </PubliceRoute>
    ),
  },
  {
    path: "/welcome",
    element: (
      <PrivateRoute>
        <Welcome />
      </PrivateRoute>
    ),
  },
  {
    path: "/biometric",
    element: (
      <PrivateRoute>
        <Biometric />
      </PrivateRoute>
    ),
  },
  {
    path: "/verification",
    element: (
      <PrivateRoute>
        <VerificationFormMainApp />
      </PrivateRoute>
    ),
  },
  {
    path: "/thank-you",
    element: (
      <PrivateRoute>
        <Thankyou />
      </PrivateRoute>
    ),
  },
  {
    path: "/user-details",
    element: (
      <PrivateRoute>
        <UserDetails />
      </PrivateRoute>
    ),
  },
]);

const App = () => {
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);

  const isNotSafariOnIOS = () => {
    const is_ios = /iP(ad|od|hone)/i.test(window.navigator.userAgent),
      is_safari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);

    return is_ios && !is_safari;
  };

  useEffect(() => {
    if (window.location.pathname == "/admin-login") {
      setLoading(true);
      window.location.href = MAIN_APP_URL;
      return;
    }

    localStorage.setItem("apiUrl", MAIN_APP_URL);
    localStorage.setItem("biometricUrl", BIOMETRIC_BACKEND_URL);
    localStorage.setItem("deepfaceUrl", DEEPFACE_BACKEND_URL);
    if (localStorage.getItem("jwt_ahrvo")) {
      // after calling LogRocket.init()

      LogRocket.init("xkhgk4/mainapp-react-and-biometrics");
      setupLogRocketReact(LogRocket);
      LogRocket.identify(localStorage.getItem("email") || "Anonymous", {
        email: localStorage.getItem("email"),

        // Add your own custom user variables here, ie:
        subscriptionType: "pro",
      });
    }
  }, []);

  if (loading) return <FullScreenLoader />;
  if (isNotSafariOnIOS()) return <SafariPage />;

  return (
    <Suspense fallback={<FullScreenLoader />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default App;
