import React from "react";

export const SafariPage = ({ navigate }) => {
  const linkUrl = window.location.origin + "/user-signup";
  return (
    <div className="nk-body bg-white npc-default pg-error no-touch nk-nio-theme">
      <div class="nk-app-root">
        <div class="nk-main ">
          <div class="nk-wrap nk-wrap-nosidebar">
            <div
              class="nk-content "
              style={{
                padding: "0 !important",

                display: "flex",
                flexDirection: "column",
              }}
            >
              <div class="nk-block nk-block-middle wide-xs mx-auto">
                <div class="nk-block-content nk-error-ld text-center">
                  <h1 style={{ marginTop: "5rem" }} class="nk-error-head">
                    Browser not supported
                  </h1>

                  <p style={{ marginTop: "3rem" }} class="nk-error-text">
                    iPhones do not support camera functionality on Chrome.
                    Please copy the link below, and use a Safari browser.
                  </p>
                  <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
                    <em
                      class="icon ni ni-b-chrome"
                      style={{
                        fontSize: "2rem",
                        color: "rgb(20, 155, 227)",
                        marginRight: "0.5rem",
                      }}
                    ></em>
                    <em
                      class="icon ni ni-b-edge"
                      style={{
                        fontSize: "2rem",
                        color: "rgb(20, 155, 227)",
                        marginRight: "0.5rem",
                      }}
                    ></em>
                    <em
                      class="icon ni ni-b-firefox"
                      style={{
                        fontSize: "2rem",
                        color: "rgb(20, 155, 227)",
                        marginRight: "0.5rem",
                      }}
                    ></em>
                    <em
                      class="icon ni ni-b-ie"
                      style={{
                        fontSize: "2rem",
                        color: "rgb(20, 155, 227)",
                        marginRight: "0.5rem",
                      }}
                    ></em>
                    <em
                      class="icon ni ni-b-opera"
                      style={{
                        fontSize: "2rem",
                        color: "rgb(20, 155, 227)",
                        marginRight: "0.5rem",
                      }}
                    ></em>
                  </div>
                  <div
                    style={{
                      marginLeft: "1rem",
                      marginRight: "1rem",
                      marginBottom: "1rem",
                    }}
                  >
                    <div className="card card-bordered">
                      <p style={{ width: "100%" }}>{linkUrl}</p>
                    </div>
                  </div>
                  <div>
                    <a
                      onClick={() =>
                        window.navigator.clipboard.writeText(linkUrl)
                      }
                      class="btn btn-outline-primary mt-2"
                    >
                      Copy Url
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
