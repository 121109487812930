import React from "react";
import FormInput from "./form-input";
import FormLabel from "./form-label";
import FormSelect from "./form-select";
import MultiSelect from "./multi-select";

export default function FormGroup({
  label,
  type,
  required,
  placeholder,
  options,
  questionIndex,
  value,
  onChange,
  id,
  invalid,
}) {
  return (
    <div className="form-group" style={{ position: "relative" }} id={id}>
      <FormLabel
        label={label}
        required={required}
        questionIndex={questionIndex}
      />
      {type == "select-box" ? (
        <FormSelect
          invalid={invalid}
          value={value}
          onChange={onChange}
          options={options}
          placeholder={placeholder}
        ></FormSelect>
      ) : type == "multiple-select" ? (
        <MultiSelect
          invalid={invalid}
          value={value}
          onChange={onChange}
          options={options}
        />
      ) : (
        <FormInput
          invalid={invalid}
          value={value}
          onChange={onChange}
          type={type}
          required={required}
          placeholder={placeholder}
          options={options}
        />
      )}
    </div>
  );
}
