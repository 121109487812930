import React from "react";

export default function FormInput({
  type,
  placeholder,
  options,
  value,
  onChange,
  invalid = false,
}) {
  let className =
    "form-control form-control-lg ng-untouched ng-pristine ng-invalid";

  return (
    <div className="form-control-group">
      <input
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={invalid ? `${className} invalid-form-input` : className}
      />
      {invalid && <span class="invalid">Required</span>}
      {/*  <div>error message</div> */}
    </div>
  );
}
/*
  className={invalid ? `${className} invalid-form-input` : className}

  {invalid && <span class="invalid">Required</span>}
*/
