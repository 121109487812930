import React from "react";

export default function NavbarListItem({
  label,
  number,
  onItemClick,
  activeIndex,
  isComplete,
}) {

  return (
    <li
      class="current"
      style={{ padding: "0.5rem", cursor: "pointer" }}
      onClick={() => onItemClick(number)}
    >
      <div class="step-item">
        <div class="step-text">
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background:
                  activeIndex == number ? "#6576ff" : "rgba(101,118,255,.15)",
                width: "2rem",
                height: "2rem",
                borderRadius: "50%",
                marginTop: "0.2rem",
                marginRight: "1rem",
              }}
            >
              <span
                style={{
                  color: activeIndex != number ? "#6576ff" : "white",
                  /*    paddingTop: "0.2rem", */
                }}
              >
                {isComplete ? <em class="icon ni ni-check"></em> : number + 1}
              </span>
            </div>
            <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
              <div class="lead-text">{label}</div>

              {/*  <div class="sub-text">Different types of profiles</div> */}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
