export const BASE_URL = "https://api.kycaml.systems/mainapp-production/api/";
export const AUTH_URL = "https://api.kycaml.systems/mainapp-production/auth/api/"
export const S3_BUCKET_NAME = "videosss";
export const MAIN_APP_URL = "https://kycaml.systems/";
export const CDN_URL = "https://d1be6uarqy1zso.cloudfront.net/";
export const BIOMETRIC_BACKEND_URL =
  "https://api.kycaml.systems/backend-production/api/";
export const DEEPFACE_BACKEND_URL =
  "https://api.kycaml.systems/deepface-production/api/";
export const TRUSTLY_CDN_URL =
  "https://cdn1.sandbox.paywithmybank.com/start/assets/institutions/";
export const initData = {
  showBusinessByDefault: false,
  apikey: "",
  isExpiryRequired: true,
  isWalletRequired: false,
  showCompanyApplicantType: false,
  client_webapp_url: "https://kycaml.systems",
  nftid: "",
  showPaymentFields: false,
  kycType: "kycwithliveness",
  env: "MAINAPP",
  company_id: "",
  apiUrl: BASE_URL,
  showOpenBankingDiv: false,
};
export const CURRENTENVIROMENT = "production";
export const ENVIROMENT = {
  STAGING: "staging",
  PRODUCTION: "production",
};
export const VIDEO_UPLOAD_TOKEN="7XH4-P8>~d#~!e2wj9QJ8P15M,M=e"
export const VIDEO_UPLOAD_URL="https://r63y44m2mvbhrs5ionq2vfsfje0vdguk.lambda-url.us-east-2.on.aws/"