import React from "react";

export default function FormLabel({ label, required, questionIndex }) {
  return (
    <div className="form-label-group">
      <label className="form-label" style={{ fontWeight: "bold" }}>
        {questionIndex}. {label}
        {required && <span class="text-danger">*</span>}
      </label>
    </div>
  );
}
