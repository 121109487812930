import React from "react";
import FormGroup from "./form-group";

export default function SectionWolfbergs({
  label,
  data,
  index,
  onChange,
  onInnerItemChange,
  showLabel,
}) {
  const getNumber = () => "0" + index;

  const getInnerQuestns = (item, i) => {
    let itemIndex = 0;

    return item.innerQuestions.map((innerItem, innerIndex) => {
      let shouldShowItem = false;
      if (
        innerItem.valueToCompare?.toLowerCase()?.trim() ==
          item.value?.toLowerCase()?.trim() &&
        innerItem.condition == "equalto"
      ) {
        shouldShowItem = true;
      }
      if (
        innerItem.valueToCompare?.toLowerCase()?.trim() ==
          item.value?.toLowerCase()?.trim() &&
        innerItem.condition == "notequalto"
      ) {
        shouldShowItem = true;
      }

      if (innerItem.showAlways) {
        shouldShowItem = true;
      }

      if (shouldShowItem) {
        itemIndex = itemIndex + 1;
        return (
          <FormGroup
            key={innerIndex}
            invalid={innerItem.invalid}
            id={innerItem.id}
            value={innerItem.value}
            onChange={(e) => onInnerItemChange(e, i, innerIndex)}
            questionIndex={`${i + 1}.${itemIndex}`}
            label={innerItem.question}
            type={innerItem.responseType}
            required={innerItem.required}
            placeholder={innerItem.responsePlaceholder}
            options={innerItem.options}
          />
        );
      }
      return null;
    });
  };

  return (
    <div className="nk-kycfm">
      <div>
        {showLabel && (
          <div className="nk-kycfm-head">
            <div class="nk-kycfm-count" id="newfirstcount">
              {getNumber()}
            </div>
            <div className="nk-kycfm-title">
              <h5 className="title">{label}</h5>
              {/*  <p class="sub-title">
              Please enter all of the required information. Once you submit the
              form, you will not be able to edit it.
            </p> */}
            </div>
          </div>
        )}

        <div
          className="nk-kycfm-content"
          style={{ borderBottom: "1px solid #e5e9f2" }}
        >
          <div className="row g-4">
            {data.map((item, i) => {
              return (
                <div className="col-12" key={i}>
                  <FormGroup
                    invalid={item.invalid}
                    id={item.id}
                    value={item.value}
                    onChange={(e) => onChange(e, i)}
                    questionIndex={i + 1}
                    label={item.question}
                    type={item.responseType}
                    required={item.required}
                    placeholder={item.responsePlaceholder}
                    options={item.options}
                  />
                  <div style={{ marginLeft: "2rem" }}>
                    {getInnerQuestns(item, i)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
