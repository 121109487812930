import React from "react";

export default function FormSelect({
  placeholder,
  options,
  value,
  onChange,
  invalid = false,
}) {
  let className = "form-control form-control-lg";

  return (
    <div style={{ position: "relative" }}>
      <select
        style={{ border: invalid ? "1px solid #ed756b" : "1px solid #dbdfea" }}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={invalid ? `${className} invalid-form-input` : className}
      >
        <option value="">
          {placeholder || "Select one of the following options"}
        </option>
        {options.map((item, index) => (
          <option key={index} value={item.value}>
            {item.value}
          </option>
        ))}
      </select>
      {invalid && <span className="invalid">Required</span>}
    </div>
  );
}
