import React from "react";
import { CDN_URL } from "../../constant";

export default function LayoutGroup({
  children,
  title,
  description,
  fileBase64,
}) {
  const styles = {
    width: "120px",
    borderRadius: "50%",
    height: "120px",
    background: `url(${fileBase64 || CDN_URL + "auth-ahrvo-logo.png"})`,

    overflow: "hidden",
    border: "1px solid #ccc",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    position: "relative",
    backgroundSize: "cover",
    marginBottom: "2rem",
  };
  return (
    <div>
      <div className="nk-wrap">
        <div className="nk-content nk-content-lg nk-content-fluid">
          <div className="container-xl wide-xl">
            <div className="nk-content-inner">
              <div className="nk-content-body">
                <div className="components-preview wide-md mx-auto">
                  <div className="nk-block-head nk-block-head-lg wide-xs mx-auto">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div id="logo-box" style={styles}></div>
                    </div>
                    <div className="nk-block-head-content text-center">
                      <h2 className="nk-block-title fw-normal">{title}</h2>
                      <div className="nk-block-des">
                        <p>{description}</p>
                      </div>
                    </div>
                  </div>

                  <div className="nk-block nk-block-lg">
                    <div className="card card-bordered">{children}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
