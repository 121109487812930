import React from "react";

export default function MultiSelect({
  options,
  value,
  onChange,
  invalid = false,
}) {
  const handleChange = (valueChanged, isChecked) => {
    if (isChecked) {
      onChange(`${value},${valueChanged}`);
    } else {
      onChange(
        value
          .split(",")
          .filter((v) => v != valueChanged)
          .join(",")
      );
    }
  };
  const checkIfValueIsChecked = (valueToCheck) => {
    return value.split(",").filter((v) => v == valueToCheck).length > 0;
  };

  let className = "custom-control-group  align-center";
  return (
    <ul
      style={{
        flexFlow: "wrap",
        position: "relative",

        border: invalid ? "1px solid #ed756b" : "1px solid #dbdfea",
        borderRadius: "4px",
        marginTop: "12px !important",
      }}
      className={invalid ? `${className} invalid-form-input` : className}
    >
      {invalid && <span className="invalid">Required</span>}
      {options.map((item, index) => {
        return (
          <li key={index} style={{ margin: "0.5rem" }}>
            <div className="custom-control custom-control-sm custom-checkbox checked">
              {checkIfValueIsChecked(item.value) ? (
                <>
                  <input
                    checked
                    onChange={() => handleChange(item.value, false)}
                    type="checkbox"
                    className="custom-control-input"
                    id={item.id}
                  />
                  <label className="custom-control-label" htmlFor={item.id}>
                    {item.value}
                  </label>
                </>
              ) : (
                <>
                  <input
                    onChange={() => handleChange(item.value, true)}
                    type="checkbox"
                    className="custom-control-input"
                    id={item.id}
                  />
                  <label className="custom-control-label" htmlFor={item.id}>
                    {item.value}
                  </label>
                </>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
}
