import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const PrivateRoute = ({ children }) => {
  useEffect(() => {
    if (localStorage.getItem("expiry") < new Date().valueOf()) {
      localStorage.removeItem("jwt_ahrvo");
      navigate("/user-login");
    }
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("jwt_ahrvo")) {
      navigate("/user-login");
    }
  });
  return children;
};

export const PubliceRoute = ({ children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("jwt_ahrvo")) {
      navigate("/welcome");
    }
  });
  return children;
};
