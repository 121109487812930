import React from "react";
export const ButtonSpinner = ({ loading, text }) => {
  if (loading)
    return (
      <div
        className="spinner-border"
        style={{ marginLeft: "0.5rem" }}
        role="status"
      ></div>
    );
  return text;
};

export const FullScreenLoader = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="spinner-border"
        style={{ width: "3rem", height: "3rem" }}
        role="status"
      ></div>
    </div>
  );
};
