import React, { useState } from "react";
import NavbarListItem from "./navbar-list-item";
import SectionWolfbergs from "../section";
import { validateSingleObject } from "../validate-form";
import { errorToast, infoToast } from "../../common/toast";

export default function QuestionGroup({
  questions,
  setActiveIndex,
  activeIndex,
  sectionsCompleted,
  handleChange,
  handleInnerItemChange,
  setQuestions,
  setSectionsCompleted,
  onComplete,
  onSaveClick
}) {
  const handlePrevClick = () => {
    setActiveIndex(activeIndex - 1);
  };

  const handleNextOrSubmitClick = () => {
    let activeKey = "";

    Object.keys(questions).forEach((key, index) => {
      if (index == activeIndex) activeKey = key;
    });
    const { isInvalid, updated } = validateSingleObject(activeKey, questions);
    setQuestions(updated);
    if (!isInvalid) {
      if (activeIndex == Object.keys(questions).length - 1) {
        onComplete();
      } else {
        setActiveIndex(activeIndex + 1);
        setSectionsCompleted((prev) => ({ ...prev, [activeKey]: true }));
      }
    } else {
      setSectionsCompleted((prev) => ({ ...prev, [activeKey]: false }));
    }
  };

  const onLeftNavCLick = (indexClicked) => {
    let keyToNavigate = "";

    Object.keys(questions).forEach((key, index) => {
      if (index == indexClicked) keyToNavigate = key;
    });

    if (sectionsCompleted[keyToNavigate]) {
      setActiveIndex(indexClicked);
      return;
    }

    errorToast("You cannot navigate to uncompleted sections");
  };

  return (
    <form
      class="nk-stepper stepper-init is-alter nk-stepper-s1"
      action="#"
      id="stepper-create-profile"
      data-step-current="first"
      novalidate="novalidate"
      style={{ display: "block" }}
    >
      <div class="row g-0 col-sep col-sep-md col-sep-xl">
        <div
          class="col-md-4 col-xl-4"
          style={{ borderRight: "1px solid #e5e9f2" }}
        >
          <div class="card-inner">
            <ul class="nk-stepper-nav nk-stepper-nav-s1 stepper-nav is-vr">
              {Object.keys(questions).map((key, index) => (
                <NavbarListItem
                  key={index}
                  activeIndex={activeIndex}
                  onItemClick={onLeftNavCLick}
                  label={key}
                  number={index}
                  isComplete={sectionsCompleted[key]}
                />
              ))}
            </ul>
          </div>
        </div>
        <div
          class="col-md-8 col-xl-8"
          style={{ borderLeft: "1px solid #e5e9f2" }}
        >
          <div class="card-inner">
            <div class="nk-stepper-content">
              {Object.keys(questions).map((key, index) => {
                if (index == activeIndex)
                  return (
                    <SectionWolfbergs
                      showLabel={false}
                      onChange={(value, index) =>
                        handleChange(key, index, value)
                      }
                      label={key}
                      key={activeIndex}
                      data={questions[key]}
                      onInnerItemChange={(
                        value,
                        indexToUpdate,
                        innerIndexToUpdate
                      ) =>
                        handleInnerItemChange(
                          key,
                          indexToUpdate,
                          innerIndexToUpdate,
                          value
                        )
                      }
                      index={activeIndex + 1}
                    />
                  );
                return null;
              })}
              <ul
                class="nk-stepper-pagination pt-4 gx-4 gy-2 stepper-pagination"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <li class="step-prev" onClick={handlePrevClick}>
                  <button
                    type="button"
                    disabled={activeIndex == 0}
                    class="btn btn-dim btn-primary"
                  >
                    Back
                  </button>
                </li>

                <li class="step-submit" onClick={handleNextOrSubmitClick}>
                  <button type="button" class="btn btn-primary">
                    {" "}
                    {activeIndex == Object.keys(questions).length - 1
                      ? "Submit"
                      : "Next"}
                  </button>
                  </li>
                  <li class="step-submit" onClick={() => onSaveClick(true)}>
                  <button type="button" class="btn btn-primary">
                  Save
                  </button>

                  </li>
                 
                
              </ul>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
